<template>
	<div class="progress-bar" :style="styleBar">
		<div :style="styleProgress"></div>
	</div>
</template>

<script>
export default {
	name: 'BaseProgressBar',
	props: {
		width: {
			type: Number,
			default: 240,
		},
		progress: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		styleBar() {
			return {
				width: this.width + 'rem',
			}
		},
		styleProgress() {
			return {
				width: Math.round((this.progress / 100) * this.width) + 'rem',
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.progress-bar {
	// width:2.4rem; // Set dynamically
	height: 0.2rem;
	background: $black-05;
	position: relative;
	border-radius: $br;
}
.progress-bar::after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	border: solid 0.01rem $black-10;
	border-radius: $br;
}
.progress-bar.hide {
	opacity: 0;
}
.progress-bar div {
	width: 0%;
	height: 100%;
	background: $primary;
	border: solid 0.01rem rgba(0, 0, 0, 0.15);
	border-radius: $br 0 0 $br;
}
</style>
